@import 'styles/includes';

.MobileSearchbar {
    @extend %nav-container;
    padding-top: 1.6rem;
    padding-bottom: 0.8rem;
    background-color: $colorWhite;
    position: fixed;
    height: 0;
    overflow: hidden;
    top: -85px;
    left: 0;

    &--Active {
        height: auto;
        top: 69px;
    }

    @include media(ml) {
        display: none;
    }

    &__Form {
        position: relative;
    }

    &__Input {
        padding: 13px 16px 11px 16px;
        width: 100%;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: $colorContrast;
        border: 1px solid $colorBlue;
        border-radius: 20px;
        position: relative;

        &::placeholder {
            font-family: $fontBold;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: $colorStone;
        }
    }

    &__Submit {
        padding: 0;
        width: 0px;
        height: 0px;
        border: 0;
        white-space: nowrap;
        text-transform: none;
        position: absolute;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        -webkit-clip-path: inset(50%);
    }

    &__DeleteButton {
        background-color: transparent;
        border: 0;
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        display: none;

        &--Visible {
            display: block;
        }
    }
}
