@import 'styles/includes';

.MobileNavigation {
    $root: &;
    padding: 8.8rem 2rem 7.4rem 2rem;
    background-color: $colorWhite;
    position: fixed;
    inset: 0;
    z-index: 9;
    transform: translateY(-100vh);
    transition: transform $transitionDelaySlow $ease;

    &--Open {
        transform: translateY(0);
    }

    @include media(ml) {
        display: none;
    }

    &__Inner {
        position: relative;
        height: 100%;
        overflow: scroll;
        visibility: hidden;
        transition: $transitionDelaySlow visibility;

        #{$root}--Open & {
            visibility: visible;
        }
    }

    &__Menu {
        margin: 3.2rem 0 0 0;
    }

    &__MenuItem {
        margin-bottom: 2rem;
    }

    &__MenuItemLabel {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: $fontBlack;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 1.5;
        letter-spacing: normal;
        color: $colorBlue;
        border: 0;
        background-color: transparent;

        &::after {
            content: '';
            margin-left: 1.5rem;
            width: 24px;
            height: 14px;
            background-image: url('/img/chevron-menu.svg');
            background-repeat: no-repeat;
            background-size: 24px 14px;
        }

        &--Expanded {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &__Toggle {
        padding: 0 0 0.4rem 0;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $colorBlue;
        background-color: transparent;
        border: 0;

        &--Active {
            position: relative;

            &::after {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $colorYellow;
                position: absolute;
                left: 0;
                bottom: -1px;
            }
        }

        &:not(:last-of-type) {
            margin: 0 44px 0 0;
        }
    }

    &__Bottom {
        padding: 1rem 0 calc(1.5rem + env(safe-area-inset-bottom)) 0;
        background-color: $colorWhite;
        border-top: 1px solid $colorYellow;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        position: fixed;
        bottom: 0;
        left: 2rem;
        right: 2rem;
    }

    &__BottomLink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__LanguageSelect {
        position: relative;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: $colorBlue;
    }

    &__LanguageButton {
        margin: 0;
        padding: 10px;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.6rem;
        color: $colorBlue;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-top: 0;
        background-color: $colorWhite;

        #{$root}__LanguageSelect--IsExpanded & {
            border-top: 0;
            border-right: 1px solid $colorBlue;
            border-bottom: 1px solid $colorBlue;
            border-left: 1px solid $colorBlue;
            border-radius: 0 0 8px 8px;
        }
    }

    &__LanguageList {
        display: none;
        padding: 10px 10px 0 10px;
        width: 100%;
        position: absolute;
        bottom: 100%;
        border-top: 1px solid $colorBlue;
        border-right: 1px solid $colorBlue;
        border-bottom: 0;
        border-left: 1px solid $colorBlue;
        border-radius: 8px 8px 0 0;
        background-color: $colorWhite;

        #{$root}__LanguageSelect--IsExpanded & {
            display: block;
        }
    }

    &__LanguageListItem {
        font-weight: 600;
    }

    &__LanguageLink {
        font-size: 1.6rem;
        padding: 10px 0;
        display: inline-block;
    }

    &__Submenu {
        display: none;

        &--Open {
            display: block;
        }
    }

    &__SubmenuList {
        margin-top: 2rem;
        padding: 0 2rem;
    }

    &__SubmenuListItem {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.71;
        color: $colorBlue;
    }

    &__SubmenuListLink {
        margin-bottom: 2rem;
        text-transform: uppercase;
        display: inline-block;
    }

    &__ListItem {
        margin-bottom: 2rem;
    }
}
